import React, { useState } from 'react';
import { motion } from 'framer-motion';
import GenPreLogo from './media/GenPreLogo.svg';
import om from './media/om.jpeg';
import Pretive from './media/Preative.png'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -80; // Adjust this value based on your header height
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <motion.header
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-r from-[#1a0033] to-[#000000] text-white p-4 shadow-lg fixed w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <img src={Pretive} alt="Pretive Logo" className='h-8 md:h-12'/>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            {['About', 'Products', 'Demo', 'Founder', 'Contact'].map((item) => (
              <motion.li key={item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <button 
                  onClick={() => handleScrollTo(item.toLowerCase())} 
                  className="text-lg font-medium hover:text-purple-500 transition-colors"
                >
                  {item}
                </button>
              </motion.li>
            ))}
          </ul>
        </nav>
        <button 
          className="md:hidden text-white"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          ☰
        </button>
      </div>
      {isMenuOpen && (
        <motion.nav 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="md:hidden mt-4"
        >
          <ul className="flex flex-col space-y-2">
            {['About', 'Products', 'Demo', 'Founder', 'Contact'].map((item) => (
              <motion.li key={item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <button 
                  onClick={() => handleScrollTo(item.toLowerCase())} 
                  className="text-lg font-medium hover:text-purple-500 transition-colors block w-full text-left"
                >
                  {item}
                </button>
              </motion.li>
            ))}
          </ul>
        </motion.nav>
      )}
    </motion.header>
  );
};

const ProductCard = ({ title, description, link, Icon }) => (
  <motion.div
    className="bg-white p-6 md:p-8 rounded-xl shadow-xl hover:shadow-2xl transition-shadow"
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
  >
    <Icon className="w-12 h-12 md:w-16 md:h-16 text-blue-600 mb-4 md:mb-6" />
    <h3 className="text-xl md:text-2xl font-bold mb-3 md:mb-4 text-gray-800">{title}</h3>
    <p className="mb-4 md:mb-6 text-gray-600 leading-relaxed">{description}</p>
    {link && (
      <a
        href={link}
        className="inline-block bg-blue-600 text-white px-4 md:px-6 py-2 rounded-full hover:bg-purple-700 transition-colors text-sm md:text-base"
        target="_blank"
        rel="noopener noreferrer"
      >
        Try Now it's Free
      </a>
    )}
  </motion.div>
);

const RocketIcon = (props) => (
  <img src={GenPreLogo} alt="GenPre Logo" {...props} 
  style={{ width: '40px', height: '50px', objectFit: 'cover', overflow: 'hidden'}}/>
);

const LightbulbIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24" stroke="#8906e6" {...props}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
  </svg>
);

const App = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted form:', { email, subject, content });
    // Here you would typically send this data to your backend
    // Reset form fields after submission
    setEmail('');
    setSubject('');
    setContent('');
  };

  return (
    <div className="bg-gray-50 font-sans min-h-screen">
      <Header />

      <main className="container mx-auto pt-24 px-4 md:px-6">
        <section id="about" className="mb-16 md:mb-24">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl md:text-5xl font-extrabold mt-5 mb-6 md:mb-8 text-center text-gray-800 leading-tight">
              Revolutionizing Education <br /> <span className="text-purple-600">with AI</span>
            </h2>
            <p className="text-lg md:text-xl text-center text-gray-600 max-w-3xl mx-auto leading-relaxed">
              At Preative Solutions, we're passionate about transforming education through cutting-edge AI technology. Our vision is to build innovative solutions that enhance learning experiences and empower educators worldwide.
            </p>
          </motion.div>
        </section>

        <section id="products" className="mb-16 md:mb-24">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center text-gray-800">Our Innovative Solutions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
            <ProductCard
              title="GenPresentation"
              description="Our flagship product, GenPresentation, is an AI-powered tool that revolutionizes the way presentations are created. Effortlessly generate engaging slides with the power of AI."
              link="https://www.genpresentation.com/"
              Icon={RocketIcon}
            />
            <ProductCard
              title="Coming Soon: AI-Powered LMS"
              description="Get ready for our upcoming AI-Powered Learning Management System that will transform the way education is delivered and managed. Stay tuned for a learning revolution!"
              Icon={LightbulbIcon}
            />
          </div>
        </section>

        <section id="demo" className="mb-16 md:mb-24">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-center text-gray-800">See GenPresentation in Action</h2>
            <div className="bg-white p-4 md:p-8 rounded-xl shadow-xl">
              <div className="max-w-2xl mx-auto">
                <div className="relative" style={{ paddingBottom: "56.25%" }}>
                  <iframe 
                    src="https://www.youtube.com/embed/kKz6RnafF9s"
                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                    title="GenPresentation Demo"
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
              <p className="text-base md:text-lg text-gray-600 leading-relaxed text-center max-w-2xl mx-auto mt-6 md:mt-8">
                Watch our demo video to see how GenPresentation can transform your presentation creation process. Experience the power of AI-driven slide generation and design optimization in action.
              </p>
            </div>
          </motion.div>
        </section>

        <section id="founder" className="mb-16 md:mb-24">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="bg-white p-6 md:p-12 rounded-xl shadow-xl"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6 text-center text-gray-800">Meet Our Founder</h2>
            <hr className="mb-6 md:mb-8"/>
            <div className="flex flex-col md:flex-row items-center justify-center">
              <div className="md:w-1/3 mb-6 md:mb-0">
                <img src={om} alt="Om Anil Gonade" className="rounded-full w-48 h-48 md:w-64 md:h-64 object-cover mx-auto" />
              </div>
              <div className="md:w-2/3 md:pl-8 lg:pl-12">
                <h3 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4 text-blue-600">Om Anil Gonade</h3>
                <p className="text-lg md:text-xl text-gray-600 leading-relaxed mb-4 md:mb-6">
                  Om Anil Gonade is the visionary founder behind Preative Solutions. With a passion for education and technology, Om has dedicated his career to revolutionizing the way we learn and teach.
                </p>
                <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                  His innovative approach to AI in education has led to the creation of GenPresentation and sets the foundation for our upcoming AI-Powered LMS. Om's mission is to make high-quality, personalized education accessible to everyone through cutting-edge AI solutions.
                </p>
              </div>
            </div>
          </motion.div>
        </section>

        <section id="contact" className="mb-16 md:mb-24">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="bg-blue-600 text-white p-6 md:p-12 rounded-2xl shadow-2xl"
      >
        <h2 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-center">Contact Us</h2>
        <div className="max-w-md mx-auto">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScM1ZYXau3nNx6IRMTCqGm3C_ZQGki_-zi-bdMGXJ5THM4LxA/viewform?embedded=true"
            width="640"
            height="876"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            className="w-full rounded-lg"
            title="Contact Form"
          >
            Loading…
          </iframe>
        </div>
      </motion.div>
    </section>
      </main>

      <footer className="bg-gray-800 text-white py-6 md:py-8">
        <div className="container mx-auto text-center">
          <p className="text-base md:text-lg">&copy; 2024 Preative Solutions. All rights reserved.</p>          
        </div>
      </footer>
    </div>
  );
};

export default App;